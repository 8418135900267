import { Button, Collapse } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import * as React from 'react';
import { IDrawerProps } from './Drawer.props';

export interface IDrawerState {
    isOpen: boolean;
}
export interface ICollapseProps {

    /** Flag to toggle collapse open */
    isOpen?: boolean;

    /** Class that needs to be applied to the button */
    className?: string;

    /** Property to set if you want the HTML tag to be something else */
    // @ts-ignore
    tag?: React.ReactType;

    /** Children for the React element */
    // tslint:disable-next-line:no-any
    children?: any;

    /** Show the component, triggers the collapse animation */
    in?: boolean;

    /** Set the timeout */
    timeout?: number;

    /** Flag to set if this is navbar */
    navbar?: boolean;

    /** CssModule Property to set any CSS classModule on the nav */
    cssModule?: object;

    /**
     * Property to set for announcing the state -(expand or collapse) on Button.
     */
    'aria-expanded'?: boolean;
}
export type GlyphPlacement = 'start' | 'end';

/**
 * Drawer - This is a full width uncontrolled collapse where the button glyph changes
 * when the drawer is open and closed
 */

export default class Drawer extends React.Component<IDrawerProps, IDrawerState> {
    public state: IDrawerState;
    private drawerOpenGlyph: string;
    private drawerCloseGlyph: string;
    private drawerGlyphClass: string;
    private isManuallyToggled: boolean;

    constructor(props: IDrawerProps) {
        super(props);
        this._toggle = this._toggle.bind(this);
        this.isManuallyToggled = false;
        this.state = { isOpen: (props.collapseProps && props.collapseProps.isOpen) || false };
        this.drawerGlyphClass = classnames('drawer__glyph',
            // tslint:disable-next-line:align
            this.props.toggleGlyphClassName ?
                this.props.toggleGlyphClassName : '');

        if (this.props.animateGlyph) {
            this.drawerOpenGlyph = classnames(this.drawerGlyphClass,
                // tslint:disable-next-line:align
                this.props.openGlyph ?
                    this.props.openGlyph :
                    this.props.closeGlyph ?
                        this.props.closeGlyph : '');
            this.drawerCloseGlyph = this.drawerOpenGlyph;
        } else {
            this.drawerOpenGlyph = classnames(this.drawerGlyphClass,
                // tslint:disable-next-line:align
                this.props.openGlyph ?
                    this.props.openGlyph : '');
            this.drawerCloseGlyph = classnames(this.drawerGlyphClass,
                // tslint:disable-next-line:align
                this.props.closeGlyph ?
                    this.props.closeGlyph : '');
        }
    }

    public render(): JSX.Element {
        const collapseProps = { ...this.props.collapseProps } as ICollapseProps;

        // If the drawer is manually toggled by pressing the button, then we use the internal state
        if (collapseProps.isOpen === undefined || this.isManuallyToggled) {
            collapseProps.isOpen = this.state.isOpen;
        } else {
            this.state.isOpen = collapseProps.isOpen;
        }
        collapseProps.children = this.props.children;
        const drawerClass = classnames('drawer',
            // tslint:disable-next-line:align
            this.props.className ?
                this.props.className : '');
        this.isManuallyToggled = false;
        return (
            <div className={drawerClass}>
                <Button
                    {...this.props.toggleButtonProps}
                    onClick={this._toggle}
                    aria-expanded={collapseProps.isOpen}
                    block
                    disabled={this.props.disabled}
                    className={'drawer__button'}
                    color='secondary'
                >
                    {this.props.radioButtonOption ? <div className='c-fauxRadio'>
                        <input type='radio' role='radiogroup' id={this.props.toggleButtonText} onClick={this._toggle} checked={this.props.radioButtonChecked} />
                        {/* tslint:disable-next-line: jsx-self-close */}
                        <label htmlFor={this.props.toggleButtonText} className='fauxRadio'></label>
                    </div> : null}
                    {this._generateDrawerButtonInnerMarkup(collapseProps.isOpen)}
                </Button>
                <Collapse {...collapseProps} />
            </div>
        );
    }

    private _generateDrawerButtonInnerMarkup(collapseState: boolean): JSX.Element {
        const drawerButtonTextClass = classnames('drawer__buttontext',
            // tslint:disable-next-line:align
            this.props.toggleButtonClassName ?
                this.props.toggleButtonClassName : '');
        if (this.props.glyphPlacement === 'end') {
            return (
                <>
                    <span className={classnames(drawerButtonTextClass, '__start')}>{this.props.toggleButtonText}</span>
                    {this._generateGlyphMarkup('drawer__glyph__end', collapseState)}
                </>
            );
        }

        if (this.props.glyphPlacement === 'start') {
            return (
                <>
                    {this._generateGlyphMarkup('drawer__glyph__start', collapseState)}
                    <span className={classnames(drawerButtonTextClass, '__end')}>{this.props.toggleButtonText}</span>
                </>
            );
        } else {
            return <span className={classnames(drawerButtonTextClass, '__start')}>{this.props.toggleButtonText}</span>;
        }
    }

    private _generateGlyphMarkup(location: string, collapseState: boolean): JSX.Element {
        let glyphClass = collapseState ? this.drawerOpenGlyph : this.drawerCloseGlyph;
        glyphClass = `${glyphClass} ${location}`;
        return (
            <span className={glyphClass} />
        );
    }

    private _toggle(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
        this.isManuallyToggled = true;
        if (this.props.onToggle) {
            this.props.onToggle();
        }
    }
}
